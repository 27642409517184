<template>
  <v-tooltip
    :disabled="item.auto"
    left
    open-delay="1000"
    :color="color"
  >
    <template #activator="{ on }">
      <v-avatar
        :color="color"
        :size="40"
        v-bind="$attrs"
        v-on="on"
      >
        <v-icon
          v-if="item.auto"
          color="white"
          small
        >
          fal fa-robot
        </v-icon>
        <span
          v-else
          class="white--text text-caption flex"
        >
          {{ item.match | numeral('0%') }}
        </span>
      </v-avatar>
    </template>
    <template v-if="item.score?.formulary">
      <v-divider />
      <div
        class="mt-1"
      >
        <v-icon
          class="mr-1 mt-n1"
          dark
          small
        >
          fa-regular fa-circle
        </v-icon>
        Different Formulary
      </div>
    </template>
    <div
      v-for="(score, name) in scoreList"
      :key="name"
      class="mt-1"
    >
      <v-icon
        class="mr-1 mt-n1"
        dark
        small
      >
        {{ computedScores[name] === score ? 'fa-solid fa-circle' : score > 0 ? 'fa-regular fa-circle-half-stroke' : 'fa-regular fa-circle' }}
      </v-icon>
      {{ name.replace('_', ' ') | capitalize }}: {{ score | percentage }}
      <template v-if="computedScores[name] != score">
        / {{ computedScores[name] | percentage }}
      </template>
    </div>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    scores: {
      allergies: 0.14,
      diagnosis: 0.06,
      gender: 0.02,
      organism: 0.68,
      pediatric: 0.10,
      age_bracket: 0.10,
      relevant_resistance: 0.14,
      resistance: 0.14,
      sensitivities: 0.14,
      formulary: 0,
    },
  }),
  computed: {
    color () {
      return 'primary lighten-' + (this.item.match < 1 ? Math.round((this.item.match - 1) * -5) : 0)
    },
    scoreList () {
      let scores = {...this.item.score}

      delete scores['formulary']

      return scores
    },
    computedScores () {
      const scores = {...this.scores}
      const divisor = [
        'resistance' in this.item.score,
        'sensitivities' in this.item.score,
        'allergies' in this.item.score,
      ].filter(Boolean).length

      scores.resistance = scores.resistance / divisor
      scores.sensitivities = scores.sensitivities / divisor
      scores.allergies = scores.allergies / divisor

      return scores
    },
  },
}
</script>
